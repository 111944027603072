export function unionState(
  oldArray,
  newArray,
  oldField = "id",
  newField = "id",
  indexInsert = undefined
) {
  const newIds = newArray.map((newItem) => newItem[newField])
  if (!oldArray.length) {
    return newArray
  } else if (indexInsert) {
    let arr = oldArray.filter(
      (oldItem) => newIds.indexOf(oldItem[oldField]) === -1
    )
    arr.splice(indexInsert, 0, ...newArray)
    /// one more method for if page already exists. We need a way replace items by id in the array and do insertion that maintains correct order. For now im doing a sort

    return sortByField(arr)
  } else {
    return [
      ...oldArray.filter((oldItem) => newIds.indexOf(oldItem[oldField]) === -1),
      ...newArray,
    ]
  }
}

export function sortByField(array, field = "createdAt") {
  return array.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[field]) - new Date(a[field])
  })
}

export function isContentNavigationLoaded(navigation_items) {
  return navigation_items.filter((e) => e.children.length > 0).length > 0
}
